/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from "react"
import { Button, message, Modal, Space, Badge, Select } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import {
  exportDownloadExcel,
  getPurchaseOrders,
  getTradingPreviewSummary,
  getTradingSchemePayment,
} from "../../promises"
import XZInput from "../../../../components/XZInput"
import exportToExcel from "../../../../utils/exportToExcel"
import type { TableRowSelection } from "antd/es/table/interface"
const withAllowClear = {
  allowClear: true,
}
import TableRender from "table-render"
import { getRangeDateOnUnitOfTime } from "../../../../utils/date/utils"
import {
  OrderLogisticsStatusEnum,
  TextStyle_OrderPaymentStatusEnum,
  TextStyle_OrderContractStatusEnum,
  OrderInvoiceStatusEnum,
  examineStatusEnum,
  TextStyle_OrderPaymentStatusNameEnum,
  ContractStatusEnum,
} from "../../../../constant/order"
import ProcureOrderApply from "./components/ApplyPayment" //申请付款
import ProcureApplyBilling from "./components/ApplyBilling" //申请开票
import { DetailPageContainer } from "../../../../components/DetailPageComponents/PageContainer"
import { DetailPageContent } from "../../../../components/DetailPageComponents/Content"
import { DetailPageHeader } from "../../../../components/DetailPageComponents/Header"
import { formatAmount } from "@/utils/utils"
import { formatNumber } from "@/utils/formatNumber"
import "./index.scss"
import _ from "lodash"
import { useQueryParamsStorage } from "../../../../hooks/useQueryParamsStorage"
import SKTable from "../../../../components/SkTable"
import ProTable from "@ant-design/pro-table"
import { ProTableColumn } from "@ant-design/pro-table/es/Store/Provide"
import { ProColumns } from "@ant-design/pro-table/es/typing"
import numeral from "numeral"
import ExportInvoice from "../../components/exportInvoice"
import XZExport from "../../../../components/XZExport"
import { downConfig } from "../../../../components/XZExport/exportConfig"
import { CustomRangePicker } from "../../../../components/CustomRangePicker"
interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}
let reqData: any = {}
const ProcureOrder = () => {
  const [totalCount, setTotalCount] = useState(0)
  const formRef: any = useRef()
  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false)
  const [isModalOpenApply, setIsModalOpenApply] = useState<Boolean>(false) //申请开票弹框
  const [procireOrederList, setProcireOrederList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]) //选中数据
  const [selectedRowKeyList, setSelectedRowKeysList] = useState<any[]>([]) //选中数据
  const [GenerationFlag, setGenerationFlag] = useState<Boolean>(false) //申请付款弹框条件
  const [previewSummaryFlag, setPreviewSummaryFlag] = useState<Boolean>(true) //申请开票弹框条件
  const [previewSummary, setPreviewSummary] = useState<any>([]) //订单列表信息汇总
  const currentTenantId = sessionStorage.getItem("tenantId")
  const queryParamsRef = useRef<any>()

  //订单列表信息汇总
  const handTradingPreviewSummary = async (params?: any) => {
    const _params = params?.ids?.length ? params : queryParamsRef.current
    const result = await getTradingPreviewSummary(_params)
    if (result.code == 200) {
      setPreviewSummary(result.data)
    }
  }
  const contractStatusFlag = useMemo(() => {
    const allTrue = selectedRowKeyList.every(
      (contract: any) => contract.contractStatus === "ALL_COMPLETED" && contract.contractNo
    )
    return !allTrue
  }, [selectedRowKeyList])
  const isDrawerOrgId = useMemo(() => {
    return selectedRowKeyList.every((key: any) => {
      const obj = procireOrederList.find((item: any) => item.id === key.id)
      console.log(obj, "obj")
      if (!obj) return false // 如果找不到对应对象，则额外条件不满足
      const { drawer, buyerId, sellerId } = obj
      if (drawer === "ALL") return true // 当 drawer 为 'ALL' 时，直接返回 true，按钮可以点击
      return (
        (drawer === "BUYER" && currentTenantId !== buyerId) ||
        (drawer === "SELLER" && currentTenantId !== sellerId)
      )
    })
  }, [selectedRowKeyList, procireOrederList, currentTenantId])
  const amtDegree = (line: any) => {
    console.log(line, 111)
    return ""
  }
  const columns: ProColumns<any, any>[] = [
    {
      dataIndex: "id",
      title: "订单ID",
      fixed: "left",
      search: false,
      width: 80,
      render: (dom, record) => {
        return <Link to={`/entity/transaction/procureOrder/${record.id}`}>{dom}</Link>
      },
    },
    { dataIndex: "buyerName", title: "采购方", search: false },
    { dataIndex: "sellerName", title: "销售方", search: false },
    { dataIndex: "sellerLegal", title: "法人", search: false },
    { dataIndex: "categoryName", title: "品类", search: false },
    { dataIndex: "contractNo", title: "合同编号", search: false },
    { dataIndex: "receiveOrgName", title: "收货单位", search: false },
    { dataIndex: "quantity", title: "数量", search: false },
    { dataIndex: "unitOfMeasure", title: "单位", search: false },
    {
      dataIndex: "sellerIncomeAmt",
      title: "卖方净得价",
      renderText: formatAmount,
      search: false,
    },
    { dataIndex: "beforeVatAmt", title: "不含税金额", renderText: formatAmount, search: false },
    { dataIndex: "afterVatAmt", title: "含税总额", renderText: formatAmount, search: false },
    { dataIndex: "vatAmt", title: "应缴增值税", renderText: formatAmount, search: false },
    { dataIndex: "platFeeAmt", title: "应缴附加税", renderText: formatAmount, search: false },
    { dataIndex: "paidRatio", title: "金额完成度", search: false },
    { dataIndex: "paidGrossAmount", title: "代扣货款", renderText: formatAmount, search: false },
    { dataIndex: "paidVatTaxAmount", title: "代扣增值税", renderText: formatAmount, search: false },
    {
      dataIndex: "paidOtherTaxAmount",
      title: "代扣附加税费",
      renderText: formatAmount,
      search: false,
    },
    { dataIndex: "paidTaxAmount", title: "代扣税款总额", renderText: formatAmount, search: false },
    { dataIndex: "invoiceCategoryConfigName", title: "开票名称", search: false },
    { dataIndex: "orderDate", title: "订单创建日期", search: false },
    {
      dataIndex: "createdTime",
      title: "订单创建日期",
      valueType: "dateRange",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      hideInTable: true,
    },
    { dataIndex: "auditUserName", title: "审核人", search: false },

    {
      dataIndex: "INVOICE_TIME",
      title: "开票日期",
      valueType: "dateRange",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      hideInTable: true,
    },
    { dataIndex: "invoiceMonth", title: "开票月份", search: false },
    {
      dataIndex: "lastInvoiceDate",
      title: "开票日期",
      search: false,
    },
    {
      dataIndex: "relationSupplyContract",
      title: "供货协议",
      render: (value, record) =>
        record.relationSupplyContract ? (
          <Badge status={"success"} text={"已关联"} />
        ) : (
          <Badge status={"warning"} text={"未关联"} />
        ),
    },
    {
      dataIndex: "contractStatus",
      title: "合同签署",
      width: 100,
      render: (value, record) => <Badge {...ContractStatusEnum[record.contractStatus]} />,
      valueEnum: {
        NO_SIGNATURE: "无需签署",
        PENDING: "未签署",
        SELLER_COMPLETED: "买方未签署",
        BUYER_COMPLETED: "卖方未签署",
        ALL_COMPLETED: "已签署",
      },
    },
    {
      dataIndex: "logisticsName",
      title: "物流状态",

      render: (value) => <Badge {...OrderLogisticsStatusEnum[value]} />,
      search: false,
      width: 100,
    },
    {
      title: "物流状态",
      dataIndex: "logisticStatus",
      valueEnum: {
        NO_COMPLETE: "未完成",
        COMPLETE: "已完成",
        PARTIAL_COMPLETE: "部分发货",
      },
      hideInTable: true,
    },
    {
      dataIndex: "paymentStatusName",
      title: "支付状态",
      width: 100,
      render: (value, record) => (
        <Badge {...TextStyle_OrderPaymentStatusNameEnum[record.paymentStatusName]} />
      ),
      search: false,
    },
    {
      dataIndex: "paymentStatus",
      title: "支付状态",
      render: (value, record) => (
        <Badge {...TextStyle_OrderPaymentStatusEnum[record.paymentStatus]} />
      ),
      fieldProps: {
        mode: "multiple",
      },
      valueType: "select",
      valueEnum: {
        PENDING: "待支付",
        PARTIAL_PAID: "部分支付",
        COMPLETED: "已支付",
        CLOSE: "已关闭",
      },
      hideInTable: true,
    },
    {
      dataIndex: "invoiceStatus",
      title: "开票状态",
      width: 100,
      render: (value, record) => <Badge {...OrderInvoiceStatusEnum[record.invoiceStatus]} />,
      search: false,
    },
    {
      dataIndex: "reviewStatus",
      title: "审核状态",
      width: 100,
      // valueEnum: examineStatusEnum,
      render: (value, record) => <Badge {...examineStatusEnum[record.reviewStatus]} />,
      search: false,
    },
    {
      hideInTable: true,
      dataIndex: "orderInvoiceStatus",
      title: "开票状态",
      fieldProps: {
        mode: "multiple",
      },
      valueType: "select",
      // valueType: "indexBorder",
      valueEnum: {
        PENDING: "未申请",
        PROCESSING: "开票中",
        COMPLETED: "已开票",
        // APPLIED: "已申请",
      },
    },

    {
      hideInTable: true,
      dataIndex: "orderReviewStatus",
      title: "审核状态",
      fieldProps: {
        mode: "multiple",
      },
      valueType: "select",
      // valueType: "indexBorder",
      valueEnum: examineStatusEnum,
      // valueEnum: {
      //   PENDING: "待审核",
      //   REFUSED: "审核驳回",
      //   REVIEWED: "审核通过",
      // },
    },
    {
      hideInTable: true,
      dataIndex: "auditUserName",
      title: "审核人",
      valueType: "text",

      fieldProps: { placeholder: "审核人", allowClear: true },
    },

    {
      hideInTable: true,
      dataIndex: "categoryName",
      title: "品类搜索",
      valueType: "text",
      search: false,
    },
    {
      hideInTable: true,
      dataIndex: "receiveOrgName",
      title: "收货单位",
      valueType: "text",
    },
    {
      hideInTable: true,
      dataIndex: "keyword",
      title: "搜索",
      fieldProps: { placeholder: "订单id，品类，采购方，销售方，收货单位搜索" },
      valueType: "text",
    },
    // {
    //   dataIndex: "actions",
    //   title: "操作",
    //   fixed: "right",
    //   search: false,
    //   width: "6rem",
    //   render: (_, record: any) => {
    //     return <Link to={`/entity/transaction/procureOrder/${record.id}`}>详情</Link>
    //   },
    // },
  ]
  const handleSelect = (item: any, flag: any) => {
    let keys: any = _.cloneDeep(selectedRowKeys),
      values: any = _.cloneDeep(selectedRowKeyList)
    if (flag) {
      // 选中
      keys = [...selectedRowKeys, item?.id]
      values = [...selectedRowKeyList, item]
    } else {
      // 取消
      keys = keys.filter((k: any) => k != item?.id)
      values = values.filter((k: any) => k?.id != item?.id)
    }
    keys = _.compact(_.uniq(keys))
    values = _.compact(_.unionBy(values, "id"))
    setSelectedRowKeys(keys)
    setSelectedRowKeysList(values)
    handTradingPreviewSummary({ ids: keys })
  }
  const handleSelectAll = (flag: any, item: any, _item: any) => {
    let keys: any = _.cloneDeep(selectedRowKeys),
      values: any = _.cloneDeep(selectedRowKeyList)
    item = _.compact(item)
    _item = _.compact(_item)

    if (flag) {
      // 全选
      values = [...selectedRowKeyList, ...item]
    } else {
      // 全部取消
      values = _.differenceBy(values, _item, "id")
    }
    keys = _.chain(values)
      .map((k: any) => k?.id)
      .uniq()
      .compact()
      .value()
    values = _.compact(_.unionBy(values, "id"))
    setSelectedRowKeys(keys)
    setSelectedRowKeysList(values)
    handTradingPreviewSummary({ ids: keys })
  }
  const rowSelection: TableRowSelection<DataType> | any = {
    alwaysShowAlert: true,
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  }
  const handleRefresh = () => {
    // formRef.current.refresh({ stay: true })
  }
  const handleInspectData = useMemo(() => {
    let _disabled = false
    selectedRowKeyList.forEach((item: any) => {
      if (
        (item?.invoiceStatus == "PENDING" && item?.reviewStatus == "REFUSED") || // 开票未申请，审核驳回
        (item?.invoiceStatus == "FAILURE" && item?.reviewStatus == "REFUSED") || // 开票失败，审核驳回
        (item?.invoiceStatus == "PENDING" && item?.reviewStatus == "PENDING") || // 开票未申请，待审核
        (item?.invoiceStatus == "FAILURE" && item?.reviewStatus == "PENDING") || // 开票失败，待审核
        (item?.invoiceStatus == "COMPLETED" && item?.reviewStatus == "REVIEWED") || // 已开票，审核通过
        (item?.invoiceStatus == "APPLIED" && item?.reviewStatus == "REVIEWED") || // 已申请，审核通过
        (item?.invoiceStatus == "COMPLETED" && item?.reviewStatus == "REFUSED") || // 已开票，审核驳回
        (item?.invoiceStatus == "APPLIED" && item?.reviewStatus == "PENDING") || // 已申请，待审核
        (item?.invoiceStatus == "PROCESSING" && item?.reviewStatus == "REVIEWED") // 开票中，已审核
      ) {
        _disabled = true
      }
    })
    return _disabled
  }, [selectedRowKeyList])

  const queryParamsStorage = useQueryParamsStorage()
  useEffect(() => {
    // console.log(formRef.current.setState({ ...formRef.current.getState(), current: 3 }))
    // formRef.current?.form.setValues(queryParamsStorage.queryParams)
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>采购订单</span>
          </Space>
        }
        breadCrumb={[
          { label: "订单管理", path: "" },
          { label: "采购订单", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className={"purchase-order-page"}>
          <ProTable
            rowKey={"id"}
            form={{
              initialValues: queryParamsStorage.queryParams,
              onValuesChange: (values) => {
                return formRef?.current?.submit()
              },
            }}
            formRef={formRef}
            headerTitle={<>采购订单列表/{totalCount}</>}
            expandable={{ showExpandColumn: false }}
            scroll={{ x: "max-content" }}
            size="small"
            pagination={{
              showSizeChanger: true,
              defaultPageSize: queryParamsStorage.queryParams?.size || 20,
              showQuickJumper: true,
              showTotal(total, range) {
                return `共${total}条`
              },
            }}
            tableAlertRender={() => (
              <div style={{ padding: "0 16", background: "#fff" }}>
                <div className="procure-order-information-bar">
                  <span>
                    数量：
                    {formatNumber(previewSummary?.quantity, { type: "number", bit_number: 2 })}吨
                  </span>
                  <span>
                    含税金额：
                    {formatNumber(previewSummary?.amountWithVat, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                    元
                  </span>
                  <span>
                    不含税金额：
                    {formatNumber(previewSummary?.amountWithoutVat, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                    元
                  </span>
                  <span>
                    增值税：
                    {formatNumber(previewSummary?.vatAmount, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                    元
                  </span>
                  <span>
                    未开票金额：
                    {formatNumber(previewSummary?.notInvoicedAmount, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                    元
                  </span>
                  <span>
                    未付款金额：
                    {formatNumber(previewSummary?.unpaidAmount, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                    元
                  </span>
                </div>
              </div>
            )}
            toolBarRender={() => [
              <div
                key={"toolBarRender"}
                style={{
                  display: "flex",
                  gap: 16,
                  alignItems: "center",
                  width: "100%",
                  padding: "0 16px",
                }}
              >
                {/* <Button
                    type="primary"
                    disabled={handleInspectData}
                    onClick={() => {
                      if (selectedRowKeys.length > 0) {
                        setIsModalOpenApply(true)
                      } else {
                        message.error("至少选择一条数据")
                      }
                    }}
                  >
                    申请开票
                  </Button> */}
                <ExportInvoice orderNos={selectedRowKeyList} orderType={"id"} />
                {/* <Button onClick={() => exportToExcel(procireOrederList, "采购订单", columns)}> */}
                {/* <Button onClick={() => exportToExcel(procireOrederList, "采购订单", columns)}>
                  数据导出
                </Button> */}
                <Button
                  disabled={contractStatusFlag}
                  onClick={async () => {
                    if (selectedRowKeys.length > 0) {
                      const params = selectedRowKeyList?.map((ie: any) => {
                        return {
                          contractNo: ie?.contractNo,
                          orderId: ie?.id,
                        }
                      })
                      console.log(params, "params")
                      await exportDownloadExcel(params)
                    } else {
                      message.error("至少选择一条数据")
                    }
                  }}
                >
                  导出合同
                </Button>
                <XZExport
                  requestParams={queryParamsStorage?.queryParams}
                  configInfo={downConfig}
                  fileName={"采购订单列表"}
                />
              </div>,
            ]}
            request={async (params) => {
              const {
                createdTime,
                INVOICE_TIME,
                orderInvoiceStatus,
                paymentStatus,
                orderReviewStatus,
                current,
                pageSize,
                contractStatus,
                auditUserName,
                receiveOrgName,
                keyword,
                ...rest
              } = params
              let createTime: any = {
                ...getRangeDateOnUnitOfTime({
                  startTime: createdTime?.[0],
                  endTime: createdTime?.[1],
                }),
              }
              let invoiceTime: any = {
                ...getRangeDateOnUnitOfTime({
                  startTime: INVOICE_TIME?.[0],
                  endTime: INVOICE_TIME?.[1],
                }),
              }
              if (createTime?.startTime)
                createTime = createTime?.startTime + "," + createTime?.endTime
              else createTime = null
              if (invoiceTime?.startTime)
                invoiceTime = invoiceTime?.startTime + "," + invoiceTime?.endTime
              else invoiceTime = null
              queryParamsRef.current = {
                createTime,
                invoiceTime,
                keywordType: "TRADE",
                orderInvoiceStatus: orderInvoiceStatus?.join(","),
                paymentStatus: paymentStatus?.join(","),
                orderReviewStatus: orderReviewStatus?.join(","),
                current: current,
                size: pageSize,
                contractStatus,
                auditUserName: auditUserName?.trim(),
                receiveOrgName: receiveOrgName?.trim(),
                keyword: keyword?.trim(),
                ...rest,
              }
              const data = await getPurchaseOrders(queryParamsRef.current)
              setTotalCount(data.total)
              setProcireOrederList(data?.records)
              handTradingPreviewSummary()
              queryParamsStorage.saveQueryParams(params)
              return {
                data: data.records,
                total: data.total,
              }
            }}
            search={{
              labelWidth: "auto",
              optionRender: false,
              collapsed: false,
            }}
            bordered={false}
            options={false}
            rowSelection={rowSelection}
            columns={columns}
          />
          <div className="apply">
            <ProcureOrderApply
              selectedRowKeys={selectedRowKeyList}
              setSelectedRowKeys={setSelectedRowKeys}
              setSelectedRowKeysList={setSelectedRowKeysList}
              isModalOpen={isModalOpen}
              handleRefresh={handleRefresh}
              setIsModalOpen={setIsModalOpen}
            />
            <ProcureApplyBilling
              selectedRowKeys={selectedRowKeyList}
              setSelectedRowKeys={setSelectedRowKeys}
              setSelectedRowKeysList={setSelectedRowKeysList}
              isModalOpen={isModalOpenApply}
              handleRefresh={handleRefresh}
              setIsModalOpen={setIsModalOpenApply}
            />
          </div>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default ProcureOrder
