import { Dropdown, Menu, Button } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { getInvoices } from "../../../Entity/promises"
import React, { useMemo } from "react"

const ExportInvoice = (props: any) => {
  const { orderNos, orderType } = props
  console.log(orderNos.map((item: any) => item.invoiceStatus))
  // 订单id集合
  const ids = useMemo(() => {
    if (orderType == "orderNo") {
      return orderNos?.map((item: any) => item.invoiceNo)
    } else {
      return orderNos?.map((item: any) => item.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNos.length])

  // 是否可以下载（状态均为开票中或已开票状态） PROCESSING, COMPLETED;
  const flag = useMemo(() => {
    if (!orderNos?.length) return true
    return !orderNos?.every((item: any) =>
      ["PROCESSING", "COMPLETED", "ISSUED", "CANCELED", "NEGATIVE_ISSUED"].includes(
        item?.invoiceStatus
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNos.length])

  const handleDown = async (type: string) => {
    if (flag) return
    let params: any = {
      formatType: type,
    }
    if (orderType == "orderNo") {
      params.invoiceNos = ids?.join(",")
    } else {
      params.orderNos = ids?.join(",")
    }
    const result: any = await getInvoices(params)
    if (result?.code == 200) {
      window.open(result.data)
    }
  }
  // "PDF", "OFD", "XML";
  const downTypes = ["PDF", "OFD", "XML"].map((item) => {
    return {
      key: item,
      label: <span>{item}</span>,
      onClick: () => {
        handleDown(item)
      },
    }
  })

  const menu = <Menu items={downTypes}></Menu>

  return (
    <>
      <Dropdown overlay={menu} placement="bottom" disabled={flag}>
        <Button>
          导出发票 <DownOutlined />
        </Button>
      </Dropdown>
    </>
  )
}
export default ExportInvoice
