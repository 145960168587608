const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    checkStatus: {
      title: "审核状态",
      type: "string",
      widget: "select",
      enum: ["WAIT_CHECK", "CHECK_REJECT", "CHECK_PASS"],
      enumNames: ["待审核", "审核拒绝", "审核通过"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    organizationType: {
      title: "组织类型",
      type: "string",
      widget: "select",
      enum: ["INDIVIDUAL", "INDIVIDUAL_BUSINESS", "ENTERPRISE", "OTHER"],
      enumNames: ["自然人", "个体户", "企业", "其他"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    checkUserName: {
      title: "审核人",
      type: "string",
      widget: "input",
      placeholder: "输入审核人搜索",
      props: withAllowClear,
    },
    timer: {
      bind: ["applyStartTime", "applyEndTime"],
      title: "申请时间",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    checkTimer: {
      bind: ["checkStartTime", "checkEndTime"],
      title: "审核时间",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    keyword: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "审核编号、入驻组织、唯一识别号、联系人搜索",
      props: withAllowClear,
    },
  },
}
